import React from "react"
import PropTypes from "prop-types"

import ComputerDeskIcon from "./icon-computer-desk"
import LightBulbIcon from "./light-bulb-icon"
import CogIcon from "./cog-icon"
import OfficeIcon from "./icon-office"

import PeopleIcon from "./people-icon"
import CogPersonIcon from "./cog-person-icon"
import IconPeopleArrow from "./icon-people-arrow"

import BrainIcon from "./brain-icon"
import AtomIcon from "./atom-icon"
import PaperShredIcon from "./paper-shred-icon"

import CircleDotsIcon from "./circle-dots-icon"
import PoundSignIcon from "./pound-sign-icon"
import InwardArrowsIcon from "./inward-arrows-icon"
import TrophyIcon from "./trophy-icon"
import CombinationIcon from "./combination-icon"

import IconStar from './icon-star'
import IconLeader from './icon-leader'
import IconInspire from './icon-inspire'
import IconCirclePeople from './icon-circle-people'
import IconClockArrows from './icon-clock-arrows'
import IconInwardArrowsSquare from './icon-inward-arrows-square'
import IconCentrePerson from './icon-centre-person'
import IconClockCog from './icon-clock-cog'

import IconCollaboration from "./icon-collaboration"
import IconCommunity from "./icon-community"
import ArrowsCogIcon from "./icon-arrows-cog"
import BinaryIcon from "./icon-binary"
import ThreeUpArrowsIcon from "./icon-three-up-arrows"
import LightBulbCogIcon from "./icon-light-bulb-cog"

import InnovationIcon from "./icon-innovation"
import InclusionIcon from "./icon-inclusion"
import CustomerExperienceIcon from "./icon-customer-experience"
import UserExperienceIcon from "./icon-user-experience"
import WorkflowsIcon from "./icon-workflows"
import DigitalInnovationIcon from "./icon-digital-innovation"


const AwardCategoryIcon = ({icon, size}) => {
  const style = {
    width: size,
    height: size,
    backgroundColor: `#ff9500`,
    borderRadius: `50%`
  }
  switch(icon) {
    case 'light-bulb-cog':
      return <LightBulbCogIcon style={style} />
    case 'three-up-arrows':
      return <ThreeUpArrowsIcon style={style} />
    case 'binary':
      return <BinaryIcon style={style} />
    case 'arrows-cog':
      return <ArrowsCogIcon style={style} />
    case 'computer-desk':
      return <ComputerDeskIcon style={style} />
    case 'light-bulb':
      return <LightBulbIcon style={style} />
    case 'cog':
        return <CogIcon style={style} />
    case 'office':
        return <OfficeIcon style={style} />
    case 'people':
        return <PeopleIcon style={style} />
    case 'cog-person':
        return <CogPersonIcon style={style} />
    case 'people-arrow':
        return <IconPeopleArrow style={style} />
    case 'brain':
        return <BrainIcon style={style} />
    case 'atom':
        return <AtomIcon style={style} />
    case 'paper-shred':
        return <PaperShredIcon style={style} />
    case 'circle-dots':
        return <CircleDotsIcon style={style} />
    case 'pound-sign':
        return <PoundSignIcon style={style} />
    case 'inward-arrows':
        return <InwardArrowsIcon style={style} />
    case 'trophy':
        return <TrophyIcon style={style} />
    case 'combination':
        return <CombinationIcon style={style} />
    case 'star':
        return <IconStar style={style} />
    case 'leader':
        return <IconLeader style={style} />
    case 'inspire':
        return <IconInspire style={style} />
    case 'circle-people':
        return <IconCirclePeople style={style} />
    case 'clock-arrows':
        return <IconClockArrows style={style} />
    case 'inward-arrows-square':
        return <IconInwardArrowsSquare style={style} />
    case 'centre-person':
        return <IconCentrePerson style={style} />
    case 'clock-cog':
        return <IconClockCog style={style} />
    case 'collaboration':
        return <IconCollaboration style={style} />
    case 'community':
        return <IconCommunity style={style} />
    case 'innovation':
        return <InnovationIcon style={style} />
    case 'digital-innovation':
        return <DigitalInnovationIcon style={style} />
    case 'inclusion':
        return <InclusionIcon style={style} />
    case 'customer-experience':
        return <CustomerExperienceIcon style={style} />
    case 'user-experience':
        return <UserExperienceIcon style={style} />
    case 'workflows':
        return <WorkflowsIcon style={style} />
    default:
      return <div style={style}></div>
  }
}

AwardCategoryIcon.propTypes = {
    icon: PropTypes.string,
    size: PropTypes.string,
}

AwardCategoryIcon.defaultProps = {
    icon: `computer-desk`,
    size: `5rem`,
}

export default AwardCategoryIcon
